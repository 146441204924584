import { observable, action, toJS } from "mobx";
import { cloneDeep } from "lodash";
class Baselib {
  constructor(type) {
    this.type = type;
    this.initData();
  }

  @observable searchData = {};

  /** 初始化查询条件 */
  @action
  initData() {
    this.searchData = cloneDeep(eval(`${this.type}Options`));
  }

  /** 编辑搜索条件 */
  @action
  mergeSearchData(options) {
    const result = toJS(this.searchData);
    for (let k in options) {
      if (result.hasOwnProperty(k)) {
        result[k] = options[k];
      }
    }
    this.searchData = result;
    return Promise.resolve(true);
  }
}
const faceOptions = {
  offset: 0,
  limit: 40,
  startTime: null,
  endTime: null,
  timerTabsActive: 3,
  cameraIds: [],
  sex: null,
  eyeGlass: null,
  placeType: null,
  feature: "",
  generation: "",
  score: 85,
  maskTags: null,
  hat: null,
  faceMask: null,
  upColor: null,
  upStyle: null,
  imgId: null,
  aids: null,
  id: null,
  minId: null,
  upperBodyTags: []
};
const bodyOptions = {
  offset: 0,
  limit: 40,
  timerTabsActive: 3,
  cameraIds: [],
  sex: null,
  placeType: null,
  startTime: null,
  endTime: null,
  upColor: null,
  lowerColor: null,
  goods: null,
  feature: "",
  minId: null,
  upperTexture: null,
  lowerTexture: null,
  nation: null,
  generation: "",
  ageSectionTags: [],
  eyeGlass: null,
  hat: null,
  imgId: null,
  id: null,
  faceMask: null,
  aids: null,
  score: 85
};
const nonVehicleOptions = {
  limit: 40,
  timerTabsActive: 3, // 时间
  cameraIds: [], // 设备id
  plateNo: "", // 车牌号
  nonMotorVehicleModel: null, // 车辆类型
  vehicleColor: null, // 车辆颜色
  score: 85, // 搜图相似度
  sex: null, // 性别
  startTime: null,
  endTime: null,
  head: null, // 头部特征
  upColor: null, // 上衣颜色
  upperTexture: null, // 上衣纹理
  feature: "",
  imgId: null,
  minId: null,
  id: null,
  vehicleType: 1 // 车辆类型
};
const vehicleOptions = {
  limit: 40,
  timerTabsActive: 3,
  cameraIds: [],
  plateNo: "",
  vehicleClasses: null,
  vehicleBrands: null,
  plateColor: null,
  startTime: null,
  endTime: null,
  vehicleColor: null,
  feature: "",
  minId: null,
  score: 85,
  imgId: null,
  id: null,
  vehicleType: 0 // 车辆类型
};
const wifiOptions = {
  limit: 40,
  offset: 0,
  timerTabsActive: 3,
  startTime: null,
  endTime: null,
  minId: null,
  cids: [],
  keywords: null
};
const accessControlOptions = {
  limit: 40,
  offset: 0,
  timerTabsActive: 3,
  startTime: null,
  minId: null,
  endTime: null,
  cameraIds: [], // 门禁设备id列表
  keywords: null, // 关键字搜索
  accessTypes: null // 开门类型
};
export default {
  face: new Baselib("face"),
  body: new Baselib("body"),
  nonVehicle: new Baselib("nonVehicle"),
  vehicle: new Baselib("vehicle"),
  wifi: new Baselib("wifi"),
  accessControl: new Baselib("accessControl")
};
