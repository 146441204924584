import { observable, action } from 'mobx';
import _ from 'lodash';
import { cloneDeep } from 'lodash';
const {deviceAndMjType} = Dict.map

export const ModelData = {
  offset: 0,
  limit: 10,
  deviceName: '',
  cid:'',
  keywords:'',
  placeIds:[],
  deviceStatus:undefined,
  deviceTypes:'-1',
  hadLocation:undefined,
  distributionState: undefined,
  includeSubOrganizations: false,
  lygroupId: '-1',
  sn: '',
  installationSite:'-1',//場所類型,
  pathId:'-1',
  industry1:'-1',//行业
};

class DeviceManagementStore {
  @observable
  activeKey = [];

  @observable
  searchData = ModelData;

  @observable
  formData = {};

  @action
  initData() {
    this.activeKey = [];
    this.initSearchForm();
    this.formData = {};
  }

  @action
  initSearchForm(){
    this.searchData = ModelData;
  }

  @action
  mergeFormData(data) {
    this.formData = Object.assign(this.formData, { ...data });
  }

  @action
  mergeSearchData(data) {
    this.searchData = Object.assign(this.searchData, { ...data });
  }
  @action
  setData(json) {
    for (var k in json) {
      if (this.hasOwnProperty(k)) {
        this[k] = json[k];
      }
    }
  }
}

export default new DeviceManagementStore();
